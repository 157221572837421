import React from "react";
import styled from "styled-components";
import { ApiImage } from "../ui/ApiImage";
import { themeImgSizes } from "~/theme";

const imgSizes = {
  tablet: "25vw",
  base: "50vw",
};

const Article = styled.a`
  width: 50%;
  position: relative;

  ${({ theme }) => theme.breakpoints.tablet} {
    width: 25%;
  }

  & .apiimage.loaded {
    img {
      transition: filter 0.125s, opacity 0.5s;
    }
  }
  
`;

const Wrapper = styled.span`
  display: block;
  width: 100%;
  height: auto;
  padding-bottom: 100%;
  position: relative;
`;

const Img = styled.span`
  display: block;
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

/* 
    @media (any-pointer: fine) {
      position: absolute;
      padding-bottom: 10px;
      &:hover {
        transform: translateY(0);
      }
    }
  `
     */

const Text = styled.span`
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  transition: opacity 0.3s;
  background-color: var(--ikon-hl-color, #ff0);

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        padding: ${props.theme.spacePx(breakpoint, 8)};
      `;
    })}


  ${({theme}) => theme.breakpoints.tabletLandscape} {
    @media (any-pointer: fine) {
      display: block;
      opacity: 0;
    
      &:hover {
        opacity: 1;
      }
    }
  }
`;

const TextTrim = styled.div`
  overflow: hidden;
  // hyphens: auto;
  text-overflow: ellipsis;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  width: 100%;
  color: #000 !important;
  ${({theme}) => theme.breakpoints.desktop} {
    -webkit-line-clamp: 12;
  }
`;

export const InstagramItem = ({ item }: { item: any }) => {
  return (
    <Article href={item?.url} target="_blank" rel="noreferrer">
      <Wrapper>
        {item?.featuredImage?.sizes && (
          <Img>
            <ApiImage
              alt=""
              ratio="1 / 1"
              imgSizes={themeImgSizes(imgSizes)}
              sizes={item?.featuredImage?.sizes}
              mode="fill"
              objectPosition={item?.featuredImage?.cropPosition}
            />
          </Img>
        )}
        <Text>
          <TextTrim>{item?.content}</TextTrim>
        </Text>
      </Wrapper>
    </Article>
  );
};
export default InstagramItem;
