import React, { MutableRefObject, useEffect, useRef } from "react";
import Link from "next/link";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperType } from "swiper";
import { Navigation, Mousewheel, Autoplay } from "swiper";
import "swiper/css";
import styled from "styled-components";

import { ApiImage } from "../ui/ApiImage";
import { useConfigContext } from "~/providers/ConfigContextProvider";
import { formatDate } from "~/utils";
import { Heading } from "../ui/Heading";
import SafeHtmlSpan from "../ui/SafeHtmlSpan";
import LinkOverlay from "../styled/LinkOverlay";
import { useInView } from "react-intersection-observer";

const SlideshowContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #000;

  & .swiper {
    height: 100%;
    width: 100%;
  }
  & .swiper-wrapper,
  & .swiper-slide {
    height: 100%;
    user-select: none;
  }

  & .swiper-button-prev {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;

    z-index: 2;
    cursor: url("/img/arrows/IKON_White_Left_64px.png") 38 32, pointer;
    cursor: -webkit-image-set(
          url("/img/arrows/IKON_White_Left_64px.png") 1x,
          url("/img/arrows/IKON_White_Left_128px.png") 2x
        )
        38 32,
      auto;

    &.swiper-button-disabled {
      cursor: url("/img/arrows/IKON_White_Left_Inactive_64px.png"), pointer;
      cursor: -webkit-image-set(
            url("/img/arrows/IKON_White_Left_Inactive_64px.png") 1x,
            url("/img/arrows/IKON_White_Left_Inactive_128px.png") 2x
          )
          38 32,
        auto;
    }
  }

  & .swiper-button-next {
    position: absolute;
    top: 0px;
    left: auto;
    right: 0;
    height: 100%;
    z-index: 2;
    cursor: url("/img/arrows/IKON_White_Right_64px.png"), pointer;
    cursor: -webkit-image-set(
          url("/img/arrows/IKON_White_Right_64px.png") 1x,
          url("/img/arrows/IKON_White_Right_128px.png") 2x
        )
        38 32,
      auto;

    &.swiper-button-disabled {
      cursor: url("/img/arrows/IKON_White_Right_Inactive_64px.png"), pointer;
      cursor: -webkit-image-set(
            url("/img/arrows/IKON_White_Right_Inactive_64px.png") 1x,
            url("/img/arrows/IKON_White_Right_Inactive_128px.png") 2x
          )
          38 32,
        auto;
    }
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `

        & .swiper-button-prev,
        & .swiper-button-next {
          width: ${props.theme.spacePx(
            breakpoint,
            ["base", "mobile"].includes(breakpoint) ? 5 : 4
          )};
        }

        & .swiper-slide {
          width: calc(100% - ${props.theme.spacePx(
            breakpoint,
            ["base", "mobile"].includes(breakpoint) ? 5 : 4
          )});
        }
        `;
    })}

  @media (any-hover: none) {
    & .swiper-button-prev,
    & .swiper-button-next {
      display: none;
    }
  }
`;

const PaddedContainer = styled.div`
  box-sizing: border-box;

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        padding: 0 ${props.theme.marginPx(breakpoint)};
        `;
    })}
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  user-select: none;
  background-color: #000;
  box-sizing: border-box;
  position: relative;
  transform: translateZ(0);
`;

const Title = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  color: #fff;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.5));

  transform: translateZ(0);

  & h2 {
    margin-bottom: 0;
  }

  @media (any-pointer: fine) {
    & a:hover {
      color: #fff;
    }
  }

  ${({ theme }) => theme.breakpoints.desktop} {
    & h1 {
      max-width: 1000px;
    }
  }
`;

export const FeaturedIntroSlideshow = ({ featured }: { featured: any }) => {
  const config = useConfigContext();
  const swiperRef = useRef<SwiperType>();
  const prevRef = useRef() as MutableRefObject<HTMLDivElement>;
  const nextRef = useRef() as MutableRefObject<HTMLDivElement>;
  const inView = useInView({
    fallbackInView: true,
  });

  useEffect(() => {
    if (typeof window === "undefined" || !swiperRef.current) return;

    if (inView.inView) {
      swiperRef.current.autoplay.start();
    } else {
      swiperRef.current.autoplay.stop();
    }
  }, [inView.inView]);

  if (!Array.isArray(featured) || featured.length === 0) return <></>;

  return (
    <SlideshowContainer
      ref={inView.ref}
      onPointerEnter={(event) => {
        if (event.pointerType === "mouse") {
          prevRef.current.style.pointerEvents = "all";
          nextRef.current.style.pointerEvents = "all";
        }
      }}
      onPointerLeave={(event) => {
        if (event.pointerType === "mouse") {
          prevRef.current.style.pointerEvents = "none";
          nextRef.current.style.pointerEvents = "none";
        }
      }}
      onTouchStartCapture={() => {
        prevRef.current.style.pointerEvents = "none";
        nextRef.current.style.pointerEvents = "none";
      }}
    >
      <Swiper
        onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
        navigation={{
          prevEl: ".swiper-button-prev",
          nextEl: ".swiper-button-next",
        }}
        speed={750}
        autoplay={{
          delay: 2500,
          disableOnInteraction: true,
        }}
        loop={true}
        modules={[Autoplay, Navigation, Mousewheel]}
        spaceBetween={0}
        grabCursor={true}
        edgeSwipeDetection
        mousewheel={{
          forceToAxis: true,
          sensitivity: 1,
          thresholdDelta: 60,
        }}
        slidesPerView="auto"
      >
        {featured.map((slide, index: number) => {
          let subtitle = "";
          let title = slide?.title;

          if (slide?.type === "event") {
            subtitle = slide?.acf?.sub_title?.trim();
          } else {
            if (slide?.acf?.artist_name?.trim()) {
              title = slide.acf.artist_name.trim();
              subtitle = slide?.title;
            }
          }

          let formattedDateBegin = null;
          let formattedDateEnd = null;

          if (slide.type !== "page") {
            formattedDateBegin = formatDate(slide?.acf?.date_begin);

            formattedDateEnd = formatDate(slide?.acf?.date_end);
          }

          let infoLine = "Exhibition";

          if (slide?.type === "page") {
            infoLine = slide?.acf?.tag_line ?? "MORE ABOUT";
          } else if (slide?.type === "event") {
            infoLine =
              slide?.taxonomies?.programme_category?.length > 0
                ? slide?.taxonomies?.programme_category
                    .map((term: any) => term.name)
                    .join("/")
                : "Event";
          }

          let timeInfo = <></>;

          if (formattedDateBegin && slide?.acf?.dates?.length === 1)
            timeInfo = (
              <>
                {" "}
                / {slide?.acf?.dates?.[0]?.time_begin}&ndash;
                {slide?.acf?.dates?.[0]?.time_end}
              </>
            );

          return (
            <SwiperSlide key={`swiper-${index}`}>
              <Slide
                key={`slideshow-slide-${index}`}
                className={
                  index === 0
                    ? "first"
                    : index === slide.length - 1
                    ? "last"
                    : ""
                }
              >
                {slide?.featuredImage?.sizes && (
                  <ApiImage
                    key={`slideshowimg-${index}`}
                    aspectRatioDirection="width"
                    alt=""
                    sizes={slide?.featuredImage?.sizes}
                    imgSizes="100vw"
                    mode="fill"
                    objectPosition={slide?.featuredImage?.cropPosition}
                  />
                )}
                <Title>
                  <PaddedContainer>
                    <Heading heading="h4" space={9} noWrap>
                      <SafeHtmlSpan html={infoLine} />
                      {formattedDateBegin && (
                        <>
                          &emsp;
                          <span style={{ fontWeight: 400 }}>
                            {formattedDateBegin}

                            {formattedDateEnd &&
                              formattedDateBegin !== formattedDateEnd && (
                                <>
                                  {formattedDateBegin && formattedDateEnd && (
                                    <>&ndash;</>
                                  )}
                                  {formattedDateEnd}
                                </>
                              )}
                            {timeInfo}
                          </span>
                        </>
                      )}
                    </Heading>

                    <Link
                      passHref
                      href={`${config.baseUrl}/${slide.type}/${slide?.slug}`}
                    >
                      <LinkOverlay>
                        <Heading
                          heading="h1"
                          space={subtitle ? 8 : 6}
                          html={title}
                        />
                      </LinkOverlay>
                    </Link>
                    {subtitle && (
                      <Heading heading="h2" space={6} html={subtitle} />
                    )}
                  </PaddedContainer>
                </Title>
              </Slide>
            </SwiperSlide>
          );
        })}
        <div ref={prevRef} className="swiper-button-prev"></div>
        <div ref={nextRef} className="swiper-button-next"></div>
      </Swiper>
    </SlideshowContainer>
  );
};
