import { ReactElement } from "react";
import type { GetStaticProps } from "next";
import NextHeadSeo from "next-head-seo";

import { appConfig } from "~/config";
import LayoutIntroSlideshow from "~/components/layouts/LayoutIntroSlideshow";
import { Header } from "~/components/app/Header";
import {
  restApiGetPostBySlugOrFallbackId,
  restApiESGetSettings,
  restApiESQuery,
} from "~/utils/restApi";
import { FlexibleRow } from "~/components/flexibleContent/FlexibleRow";
import { FeaturedIntroSlideshow } from "~/components/content/FeaturedIntroSlideshow";
import { Footer } from "~/components/app/Footer";
import VerticalSlider from "~/components/ui/VerticalSlider";
import { WhatsOnListing } from "~/components/content/WhatsOnListing";
import PageMargins from "~/components/styled/PageMargins";
import { Heading } from "~/components/ui/Heading";
import {
  HeaderTwoCol,
  HeaderTwoColCol,
} from "~/components/styled/HeaderTwoCol";
import Link from "next/link";
import { VerticalSliderItemWithTeaser } from "~/components/content/VerticalSliderItemWithTeaser";
import { sortEventsByDateAndTime } from "~/utils/eventsHelper";
import { InstagramPosts } from "~/components/content/InstagramPosts";

const Home = ({
  currentPage,
  current,
  upcoming,
  currentEvents,
  news,
}: {
  currentPage: any;
  current: any;
  upcoming: any;
  currentEvents: any;
  news: any;
}) => {
  return (
    <>
      <NextHeadSeo
        canonical={currentPage?.yoast_head_json?.canonical}
        title={currentPage?.yoast_head_json?.title ?? currentPage?.title}
        description={currentPage?.yoast_head_json?.description}
        og={{
          title: currentPage?.yoast_head_json?.og_title,
          type: currentPage?.yoast_head_json?.og_type,
          siteName: currentPage?.yoast_head_json?.og_site_name,
          image: currentPage?.yoast_head_json?.twitter_image,
        }}
        twitter={{
          card: "summary_large_image",
        }}
      />
      <Header hasHero isHome />
      <Header isOverlay isHome />
      <div id="content" style={{ position: "absolute" }} />
      {currentPage?.acf?.featured?.length > 0 && (
        <FeaturedIntroSlideshow featured={currentPage?.acf?.featured} />
      )}

      <main>
        {currentPage?.acf?.content?.length > 0 ? (
          <FlexibleRow field="content" content={currentPage?.acf?.content} />
        ) : (
          <div />
        )}

        {currentEvents?.data?.length > 0 && (
          <>
            <WhatsOnListing
              keyPrefix="past"
              items={sortEventsByDateAndTime(currentEvents?.data)}
              heading={
                <PageMargins>
                  <HeaderTwoCol>
                    <HeaderTwoColCol>
                      <Heading heading="h3" asTag space={8} inline>
                        Events
                      </Heading>
                      <Link href="/whats-on/events/" passHref>
                        <a>
                          <Heading
                            heading="h4"
                            space={9}
                            inline
                            html="VIEW ALL"
                          />
                        </a>
                      </Link>
                    </HeaderTwoColCol>
                  </HeaderTwoCol>
                </PageMargins>
              }
            />
          </>
        )}

        {current?.data?.length > 0 && (
          <VerticalSlider
            spaceHeight={{
              base: [1, 2, 8],
              mobile: [1, 2, 8],
              tablet: [2, 3, 8],
              desktop: [2, 3, 8],
              screen: [2, 3, 8],
            }}
            header={"Current Exhibitions"}
            hasNav
            moreLinkLabel="View all"
            moreLinkUrl="/whats-on/exhibitions"
          >
            {current?.data?.map((item: any, index: number) => (
              <VerticalSliderItemWithTeaser
                item={item}
                key={`home-current-${index + 1}`}
              />
            ))}
          </VerticalSlider>
        )}

        {upcoming?.data?.length > 0 && (
          <VerticalSlider
            spaceHeight={{
              base: [1, 2, 8],
              mobile: [1, 2, 8],
              tablet: [2, 3, 8],
              desktop: [2, 3, 8],
              screen: [2, 3, 8],
            }}
            header={"Upcoming Exhibitions"}
            hasNav
            moreLinkLabel="View all"
            moreLinkUrl="/whats-on/exhibitions"
          >
            {upcoming?.data?.map((item: any, index: number) => (
              <VerticalSliderItemWithTeaser
                item={item}
                key={`home-upcoming-${index + 1}`}
              />
            ))}
          </VerticalSlider>
        )}

        {news?.data?.length > 0 && (
          <VerticalSlider
            spaceHeight={{
              base: [1, 2, 8],
              mobile: [1, 2, 8],
              tablet: [2, 3, 8],
              desktop: [2, 3, 8],
              screen: [2, 3, 8],
            }}
            header={"Latest News"}
            hasNav
            moreLinkLabel="View all"
            moreLinkUrl="/news/"
          >
            {news?.data?.map((item: any, index: number) => (
              <VerticalSliderItemWithTeaser
                item={item}
                key={`home-upcoming-${index + 1}`}
              />
            ))}
          </VerticalSlider>
        )}

        <InstagramPosts />
      </main>
      <Footer />
    </>
  );
};

export const getStaticProps: GetStaticProps = async (context) => {
  const token = (context?.previewData as any)?.token;

  const currentPage = await restApiGetPostBySlugOrFallbackId(
    "page",
    "home",
    token
  );

  let today = new Date().toISOString().split("T")?.[0];

  const upcoming = await restApiESQuery({
    type: "exhibition",
    perPage: 5,
    orderby: "meta.date_begin.date",
    order: "asc",
    dateBegin: today,
    dateBeginCompare: "gt",
  });

  const current = await restApiESQuery({
    type: "exhibition",
    perPage: 5,
    orderby: "meta.date_begin.date",
    order: "asc",
    dateBegin: today,
    dateBeginCompare: "lte",
    dateEnd: today,
    dateEndCompare: "gte",
  });

  const currentEvents = await restApiESQuery({
    type: "event",
    perPage: 5,
    orderby: "meta.date_begin.date",
    order: "asc",
    dateEnd: today,
    dateEndCompare: "gte",
  });

  const news = await restApiESQuery({
    type: "post",
    perPage: 5,
    orderby: "post_date",
    order: "desc",
  });

  const frontendSettings = await restApiESGetSettings();

  if (!currentPage)
    return {
      props: {
        frontendSettings,
      },
      notFound: true,
      revalidate: frontendSettings?.options?.copyrightNotice ? 60 : 15,
    };

  return {
    props: {
      frontendSettings,
      currentPage: currentPage ?? null,
      upcoming,
      current,
      currentEvents,
      news,
    },
    revalidate: frontendSettings?.options?.copyrightNotice
      ? appConfig.revalidateInterval("homepage", {
          date: currentPage?.date,
          modified: currentPage?.modified,
        })
      : 15,
  };
};

Home.getLayout = function getLayout(page: ReactElement) {
  return <LayoutIntroSlideshow>{page}</LayoutIntroSlideshow>;
};
export default Home;
