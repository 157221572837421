import React from "react";
import styled from "styled-components";
import Link from "next/link";
import { Heading } from "../ui/Heading";
import { ApiImage } from "../ui/ApiImage";
import { formatDate } from "~/utils";
import { useConfigContext } from "~/providers/ConfigContextProvider";
import LinkOverlay from "../styled/LinkOverlay";
import TextWithArrow from "../ui/TextWithArrow";
import SafeHtmlSpan from "../ui/SafeHtmlSpan";
import { themeImgSizes, themeSpace, themeSpacePx } from "~/theme";
import SafeHtmlDiv from "../ui/SafeHtmlDiv";

const imgSizes = {
  ...["screen", "desktop", "tabletLandscape", "tablet"].reduce(
    (carry: any, b: string) => ({
      [b]: `${themeSpace(b, 3) + themeSpace(b, 2)}px`,
      ...carry,
    }),
    {}
  ),
  ...["mobile", "base"].reduce(
    (carry: any, b: string) => ({
      [b]: themeSpacePx(b, 1),
      ...carry,
    }),
    {}
  ),
};

const Article = styled.article<{ hasSubtitle: boolean; hasImage: boolean }>`
  height: 100%;
  position: relative;
  transform: translateZ(0);
  & .arrow {
    display: none;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap:  ${props.theme.spacePx(breakpoint, 8)};
        `;
    })}

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
        width:  min(80vw, ${props.theme.spacePx(breakpoint, 1)});
      `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
        width:  ${
          props.theme.space(breakpoint, 2) + props.theme.space(breakpoint, 3)
        }px;
      `;
    })}


  ${({ theme }) => theme.breakpoints.tablet} {
    & .arrow {
      display: block;
      opacity: 0;
      transition: opacity 0.3s;
    }

    @media (any-pointer: fine) {
      &:hover {
        .arrow {
          opacity: 1;
        }
      }
    }
  }

  & .apiimage.loaded {
    img {
      transition: filter 0.125s, opacity 0.5s;
    }
  }

  @media (any-pointer: fine) {
    &:hover {
      .arrow {
        opacity: 1;
      }
    }
  }
`;

const Wrapper = styled.div<{ hoverText: boolean }>`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  overflow: hidden;
  ${({ hoverText }) =>
    hoverText
      ? `
    @media (any-pointer: fine) {
      display: block;
      height: 100%;
      position: relative;

      &::after {
        display: block;
        z-index: 3;
        content: "";
        width: 100%;
        height: 10px;
        background: linear-gradient(
          var(--ikon-bg-off-color),
          var(--ikon-bg-color)
        );
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  `
      : ""}

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap:  ${props.theme.spacePx(breakpoint, 8)};
        `;
    })}
`;

const Img = styled.div`
  overflow: hidden;

  width: 100%;

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
        height:  min(80vw, ${props.theme.spacePx(breakpoint, 1)});
      `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
        height: ${props.theme.spacePx(breakpoint, 2)};
      `;
    })}
`;

const Text = styled.div<{ minHeight: boolean; hoverText: boolean }>`
  bottom: 0;
  z-index: 2;
  background-color: var(--ikon-bg-color, #fff);
  width: 100%;
  transition: transform 0.3s;

  ${({ hoverText }) =>
    hoverText
      ? `
    @media (any-pointer: fine) {
      position: absolute;
      padding-bottom: 10px;
      &:hover {
        transform: translateY(0);
      }
    }
  `
      : ""}

  ${(props) =>
    props.theme.apply(["base", "mobile"], (breakpoint: string) => {
      return `
        padding-top: ${props.theme.spacePx(breakpoint, 9)};
        padding-right: ${props.theme.spacePx(breakpoint, 9)};
        ${
          props.minHeight
            ? `min-height: ${(props.theme.space(breakpoint, 2) + 10).toFixed(
                2
              )}px;`
            : ""
        }
        ${
          props.hoverText
            ? `
          @media (any-pointer: fine) {
            transform: translateY(calc(100% - ${(
              props.theme.space(breakpoint, 2) + 10
            ).toFixed(0)}px));
          }
        `
            : ""
        }
      `;
    })}

  ${(props) =>
    props.theme.apply(["tablet", "desktop", "screen"], (breakpoint: string) => {
      return `
        padding-top: ${props.theme.spacePx(breakpoint, 9)};
        padding-right: ${props.theme.spacePx(breakpoint, 9)};
        ${
          props.minHeight
            ? `min-height: ${(props.theme.space(breakpoint, 3) + 10).toFixed(
                2
              )}px;`
            : ""
        }
        ${
          props.hoverText
            ? `
          @media (any-pointer: fine) {
            transform: translateY(calc(100% - ${(
              props.theme.space(breakpoint, 3) + 10
            ).toFixed(0)}px));
          }
        `
            : ""
        }
      `;
    })}

  & p:last-child {
    margin-bottom: 0;
  }
`;

const ArticleHeader = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr auto;

  & .arrow {
    position: absolute;
  }

  ${(props) =>
    props.theme.apply("default", (breakpoint: string) => {
      return `
        column-gap:  ${props.theme.spacePx(breakpoint, 8)};
        & .arrow {
          right: ${props.theme.spacePx(breakpoint, 9)};
        }
      `;
    })}
`;

export const VerticalSliderItemWithTeaser = ({ item }: { item: any }) => {
  const config = useConfigContext();

  let formattedDateBegin = formatDate(item?.acf?.date_begin);
  const formattedDateEnd = formatDate(item?.acf?.date_end);

  let timeInfo = <></>;
  let hasTimeInfo = false;
  if (formattedDateBegin && item?.acf?.dates?.length === 1) {
    hasTimeInfo = true;
    timeInfo = (
      <>
        {" "}
        / {item?.acf?.dates?.[0]?.time_begin}&ndash;
        {item?.acf?.dates?.[0]?.time_end}
      </>
    );
  }

  let infoLine =
    item?.type === "event"
      ? item?.taxonomies?.programme_category?.length > 0
        ? item?.taxonomies?.programme_category
            .slice(
              0,
              hasTimeInfo ? 2 : item?.taxonomies?.programme_category?.length
            )
            .map((term: any) => term.name)
            .join("/")
        : "Event"
      : "Exhibition";

  const hasArtistName = item?.acf?.artist_name?.trim();

  const hasSubtitle = item?.acf?.sub_title?.trim() || hasArtistName;

  const title =
    hasArtistName && item?.type === "exhibition"
      ? item?.acf?.artist_name
      : item?.title;

  if (item?.type === "post") {
    formattedDateBegin = formatDate(item?.date);
    infoLine = item?.acf?.type?.trim()
      ? item?.acf?.type?.trim()
      : item?.taxonomies?.category?.[0]?.name ?? "News";
  }

  let url = `${config.baseUrl}/${
    item.type === "post" ? "news/view" : item.type
  }/${item?.slug}`;
  if (item?.type === "page") {
    url = item?.url;
  }

  return (
    <Article hasSubtitle={hasSubtitle} hasImage={item?.featuredImage?.sizes}>
      <Wrapper hoverText={item?.type !== "page"}>
        {item?.featuredImage?.sizes && (
          <Img>
            <ApiImage
              alt=""
              imgSizes={themeImgSizes(imgSizes)}
              sizes={item?.featuredImage?.sizes}
              mode="fill"
              objectPosition={item?.featuredImage?.cropPosition}
            />
          </Img>
        )}
        <Text
          minHeight={item?.type !== "page" || !!item?.acf?.teaser}
          hoverText={item?.type !== "page"}
        >
          {item?.type !== "page" && (
            <ArticleHeader>
              <Heading heading="h4" space={8} noWrap>
                <SafeHtmlSpan html={infoLine} />
                &emsp;
                <span style={{ fontWeight: 400 }}>
                  {formattedDateBegin}

                  {formattedDateEnd &&
                    formattedDateBegin !== formattedDateEnd && (
                      <>
                        {formattedDateBegin && formattedDateEnd && <>&ndash;</>}
                        {formattedDateEnd}
                      </>
                    )}
                  {timeInfo}
                </span>
              </Heading>

              <Heading heading="h4" space={8} className="arrow">
                <TextWithArrow align="right" heading="h4" size={9}>
                  {item?.type === "post" ? "Read" : "See"} more
                </TextWithArrow>
              </Heading>
            </ArticleHeader>
          )}
          <Link passHref href={url}>
            <LinkOverlay top="-500px" height="1200px">
              <Heading
                heading="h2"
                space={hasSubtitle ? 9 : 8}
                html={title}
                maxLines={hasSubtitle ? 2 : 4}
              />
            </LinkOverlay>
          </Link>
          {hasSubtitle && (
            <Heading
              heading="h3"
              space={8}
              fontWeight="400"
              html={item?.acf?.sub_title ?? item?.title}
            />
          )}

          {item?.acf?.teaser && <SafeHtmlDiv html={item?.acf?.teaser} />}
        </Text>
      </Wrapper>
    </Article>
  );
};
export default VerticalSliderItemWithTeaser;
