import React, { useEffect, useState } from "react";
import { useConfigContext } from "~/providers/ConfigContextProvider";
import { InstagramItem } from "./InstagramItem";
import styled from "styled-components";
import PageMargins from "../styled/PageMargins";
import { HeaderTwoCol, HeaderTwoColCol } from "../styled/HeaderTwoCol";
import { Heading } from "../ui/Heading";
import { useSettingsContext } from "~/providers/SettingsContextProvider";

const InstagramContainer = styled.div``;

const InstagramList = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #000;
`;

export const InstagramPosts = () => {
  const [items, setItems] = useState<any>([]);
  const config = useConfigContext();
  const settings = useSettingsContext();

  useEffect(() => {
    if (typeof window === "undefined") return;

    const controller =
      typeof AbortController !== "undefined"
        ? new AbortController()
        : undefined;

    const get = async () => {
      try {
        const response: any = await fetch(
          `${config.cmsUrl}/wp-content/plugins/ikon-custom/rest-api-query.php?type=instagram_post&orderby=post_date&order=DESC&per_page=4`,
          {
            signal: controller?.signal,
          }
        );
        const data = await response.json();

        if (data?.data?.length > 0) {
          setItems(data?.data);
        }
      } catch (err: any) {}
    };

    get();

    return () => {
      if (controller) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!settings?.options?.socialMediaInstagram) return <></>;
  return (
    <>
      {items?.length > 0 && (
        <PageMargins spaceBottom={5}>
          <HeaderTwoCol>
            <HeaderTwoColCol>
              <Heading heading="h3" asTag space={8} inline>
                Instagram
              </Heading>
              <a
                href={settings?.options?.socialMediaInstagram}
                target="_blank"
                rel="noreferrer"
              >
                <Heading
                  heading={"h4"}
                  space={9}
                  inline
                  html="View our profile"
                />
              </a>
            </HeaderTwoColCol>
          </HeaderTwoCol>
          <InstagramContainer
            key={`instagram`}
          >
            <InstagramList>
              {items?.map((item: any, index: number) => {
                return (
                  <InstagramItem item={item} key={`related-${index + 1}`} />
                );
              })}
            </InstagramList>
          </InstagramContainer>
        </PageMargins>
      )}
    </>
  );
};
