const convertTimeStringToDate = (tString: string) => {
  const isAm = tString.toLowerCase().indexOf("am") > -1;
  const isPm = tString.toLowerCase().indexOf("pm") > -1;
  const cleanTime = tString.toLowerCase().replace("am", "").replace("pm", "");

  let time = "10:00";

  const parts = cleanTime.split(".");
  if (parts.length == 2) {
    if (isAm) {
      const hours = parseInt(parts[0]);
      if (hours < 10) {
        time = `0${hours}:${parts[1]}`;
      } else {
        time = `${hours}:${parts[1]}`;
      }
    }

    if (isPm) {
      const hours = parseInt(parts[0]);
      if (hours < 12) {
        time = `${hours + 12}:${parts[1]}`;
      } else {
        time = `${hours}:${parts[1]}`;
      }
    }
  }

  return new Date(`April 25, 2022 ${time}:00`);
};

export const sortEventsByDateAndTime = (data: any[], direction = "asc") => {
  return (data ?? []).sort((e1: any, e2: any) => {
    if (e1?.acf?.date_begin && e2?.acf?.date_begin) {
      if (e1?.acf?.date_begin < e2?.acf?.date_begin)
        return direction === "asc" ? -1 : 1;
      if (e1?.acf?.date_begin > e2?.acf?.date_begin)
        return direction === "asc" ? 1 : -1;

      if (e1?.acf?.date_begin === e2?.acf?.date_begin) {
        if (e1?.acf?.dates?.length && e2?.acf?.dates?.length) {
          try {
            const e1StartTime = convertTimeStringToDate(
              e1?.acf?.dates[0]?.time_begin ?? ""
            );
            const e2StartTime = convertTimeStringToDate(
              e2?.acf?.dates[0]?.time_begin ?? ""
            );

            if (e1StartTime.getTime() < e2StartTime.getTime())
              return direction === "asc" ? -1 : 1;
            if (e1StartTime.getTime() > e2StartTime.getTime())
              return direction === "asc" ? 1 : -1;
          } catch (err: any) {
            // do nothing
          }
        }
      }
    }

    return 0;
  });
};
